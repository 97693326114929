import { defineStore } from 'pinia';
import { reactive, inject, ref, computed } from 'vue';
import useStoreSession from '@/store/SessionStore';
import handleNotAuthRest from '@/utils/notauthRest';
import useProgressStore from '@/store/ProgressStore';
import { useRouter } from 'vue-router';
import handleAuthRest from '@/utils/authRest';

const useStoreUser = defineStore('user', () => {
    const router = useRouter();
    const config = inject('config');
    const user = reactive({...config.account});
    const error = ref('');
    const storeSession = useStoreSession();
    storeSession.initialize()
    const storeProgress = useProgressStore();
    const session = storeSession.session;
    

    

    const isAuthorized = computed(() => {
      return !!session.token
    })
    

    const saveUserData = async () => {
        
        try {
            const res = await handleAuthRest('/auth/userdata/'+user.id, 'POST', {
                user
            }, session);
            if (res.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }


    

    const reset = () => {
        // logging out or reset
        storeSession.reset();
        Object.assign(user, config.account);
    };


    const getCloudDomains = async () => {
        
        
        const resp =  await handleAuthRest('/auth/domaindata/'+user.id, 'GET', null, session);

        const data = await resp.data

        

        storeProgress.cloudGeneratedNames = [...await data]

        

    }
    
        
    

  

    const credentialCheck = () => {
        const trimmedPassword = user.password ? user.password.trim() : '';
        const trimmedEmail = user.email ? user.email.trim() : '';

        if (!trimmedPassword && !trimmedEmail) {
            throw new Error('Either username or email must be provided.');
        }

        if ((user.password && !trimmedPassword) || (user.email && !trimmedEmail)) {
            throw new Error('Username or email cannot be only whitespace.');
        }

        if (trimmedPassword.length < 6) {
            throw new Error('Password must be at least 6 characters long.');
        }

        return { trimmedEmail, trimmedPassword };
    };

    const register = async () => {
      
        try {
            const { trimmedEmail, trimmedPassword } = credentialCheck();
            const res = await handleNotAuthRest('/account/register', 'POST', {
                email: trimmedEmail,
                password: trimmedPassword,
            });
            if (res.status === 201) {
                user.password = undefined;
                error.value = '';
                const {user: userData, accessToken,  } = await res.json();
                Object.assign(user, await userData);
                storeSession.saveSessionInfo(await accessToken);
            } else {
                reset();
            }
        } catch (err) {
            console.log("ERROR", err.message);
            error.value = err.message;
        }
      
    };

    const login = async () => {
        try {
            const { trimmedEmail, trimmedPassword } = credentialCheck();
            const res = await handleNotAuthRest('/account/login', 'POST', {
                email: trimmedEmail,
                password: trimmedPassword,
            });
            if (res.status === 201) {
                user.password = undefined;
                error.value = '';
                const {user: userData, accessToken,  } = await res.json();
                Object.assign(user, await userData);
                storeSession.saveSessionInfo(await accessToken);
                await getCloudDomains();
            } else {
                reset();
            }
        } catch (err) {
            error.value = err.message;
        }
    };


    const saveLocalToCloud = async() => {
        // this will save the local generated names to the cloud if user is logged in 
        console.log(user.id, "USER ID ")
        try {  
            await handleAuthRest('/auth/domaindata/'+user.id, 'POST', {
                data: storeProgress.generatedNames
            }, session);

            storeProgress.cloudGeneratedNames = [...storeProgress.cloudGeneratedNames, ...storeProgress.generatedNames.filter(e => e.bookmarked)] 
            storeProgress.clearGeneratedNames();
            return true;
        } catch (err) {
            return false;
        }
    }


    const saveProgress = async () => {
        // this will save the generated names to the temporary table in the database if user not logged in 
        try {
            storeProgress.cloudGeneratedNames = storeProgress.generatedNames;
            await handleNotAuthRest('/saveprogress', 'POST', {
                email: user.email,
                data: storeProgress.generatedNames
            });

            storeProgress.clearGeneratedNames();
            user.email = '';
            return true;
        } catch (err) {
            return false;
        }
    };

    const logout = reset;


    return {
        user,
        storeProgress,
        error,
        isAuthorized,
        register,
        reset,
        login,
        logout,
        saveProgress,
        saveLocalToCloud,
        saveUserData,
    };
});

export default useStoreUser;
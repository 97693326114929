import { defineStore } from 'pinia';
import { ref, inject, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';

const useStoreSession = defineStore('session', () => {
    const router = useRouter();
    const config = inject('config');
    const session = reactive({...config.session});

    watch(session, () => {
        
    });

    const loadSessionFromLocalStorage = () => {
        // if there is a accesstoken in local storage its will be loaded 
        try {
            
            const storageVal = window.localStorage.getItem('accessTokenJWT');
            const storageValParsed = JSON.parse(storageVal)
            
            if (storageVal !== null) {
                Object.assign(session, { ...storageValParsed });
            } else {
                // get new access token, if refresh token is there! 

            }
            
            
        } catch (e) {
            console.log("NO Accesstoken LOCAL STORAGE");
        }
    };

    const reset = () => {
        Object.assign(session, config.session);
        // delete it form state
        window.localStorage.removeItem('accessTokenJWT');
        // remove the token from local storage
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; HttpOnly";
        // cookie will be removed because of expired date 
        
    };
    const saveSessionInfo = async (accessToken) => {
        try {
            const accessTokenObject = {
                ...config.session,
                token: accessToken,
                //time: new Date().getTime() + 3600000  // after one hour it will be removed by browser
                time: new Date().getTime() + 60000*3  // after 60 Seconds it will be removed
            };
    
            
            const promises = [
               
                new Promise(resolve => {
                    window.localStorage.setItem('accessTokenJWT', JSON.stringify(accessTokenObject));
                    resolve();
                }),

                new Promise(resolve => {
                    Object.assign(session, accessTokenObject);
                    resolve();
                })
            ];
            await Promise.all(promises);
    

            await router.push('/auth/dashboard');
        } catch (error) {
            console.error("Error saving session info:", error);
        }
    };

    const initializers = [];
    const isInitialized = ref(false);

    const addInitializer = p_initializer => {
        initializers.push(new Promise(resolve => p_initializer(resolve)));
    };

    const initialize = async () => {
        await Promise.all(initializers);
        isInitialized.value = true;
        //console.log("Session initialized");
        // After store is initialized it will load access token from local storage or request one if 
        // http cookie is there 
        loadSessionFromLocalStorage()
    };

    

    return { session, addInitializer, initialize, isInitialized, saveSessionInfo, reset };
});

export default useStoreSession;
import { createRouter, createWebHistory } from 'vue-router'
import useStoreUser from '@/store/StoreUser'



const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/PricingView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/LegalView.vue')
  },
  {
    path: '/generate',
    name: 'generate',
    component: () => import('@/views/GenerateView.vue')
  },
  {
    path: '/auth/dashboard',
    name: 'dashboard',
    component: () => import('@/views/DashboardView.vue'),
    beforeEnter: (to, from, next) => {
      const storeUser = useStoreUser();
      
      if (storeUser.isAuthorized) {
        next();
      } else {
        next({ name: 'login' });
      }
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue'),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_BASE_URL),
  routes
})

export default router



const handleNotAuthRest = async(path, method, body) => {
    // Clear Seperation between Auth and Not Auth Rest
    
    
    return await fetch(`${process.env.VUE_APP_BASE_URL}${path}`, {
        method, //POST;GET;PUT;DELETE 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include',
    });
}


export default handleNotAuthRest;
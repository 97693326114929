import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import handleNotAuthRest from '@/utils/notauthRest';
import handleAuthRest from '@/utils/authRest';

const useProgressStore = defineStore('progress', () => {
  const generatedNames = ref([]); // Array to store generated names
  const cloudGeneratedNames = ref([])  // DB names, and the other is locally generated names 


  watch(cloudGeneratedNames, (newVal) => {
    //console.log(newVal, "NEW WERT GENERIERT")
  })


  const addGeneratedNames = (names) => {
    // Add new names to existing array
    generatedNames.value = [...generatedNames.value, ...names];
  };


  const clearGeneratedNames = () => {
    // Clear all generated names
    generatedNames.value = [];
    
  };

  const toggleBookmark = (index) => {
    // Bookmark a generated name
    if (generatedNames.value[index]) {
      generatedNames.value[index].bookmarked = !generatedNames.value[index].bookmarked;
    }
  };

  const deleteGeneratedName = (index) => {
    // Delete a generated naem
    if (generatedNames.value[index]) {
      generatedNames.value.splice(index, 1);
    }
  };

  const updateGeneratedName = (index, newName) => {
    // Customaize a generated name
    if (generatedNames.value[index]) {
      generatedNames.value[index].text = newName;
    }
  };




  const handleGenerateNames  = async(minLength , maxLength, additional, categories) => {
    try {

        const response = await handleNotAuthRest('/generatenames', 'POST', {
            categories: categories.value.filter(item => item[1]).map(item => item[0]), minLength, maxLength, additional 
        });

        if (!response.ok) throw new Error('Failed to fetch data');
        const newNames = await response.json();

        

        const domainResponse = await handleNotAuthRest('/checkdomain', 'POST', {
            names: await newNames.map(e => e.text) 
        }); 

        if (!domainResponse.ok) throw new Error('Failed to check domains');
        const domainData = await domainResponse.json();

        const combinedData = newNames.map(generatedName => {
            let foundDomains = domainData.find(item => item.name === generatedName.text);
            let { name, ...domains } = foundDomains;
            name+''
            
            return { 
                ...generatedName, 
                domains: foundDomains ? domains : [] 
            };
        });

        
        addGeneratedNames(combinedData)
        //return combinedData

    } catch (error) {
        console.error('Error:', error.message);
    }
  }

  return {
    generatedNames,
    cloudGeneratedNames,
    handleGenerateNames,
    addGeneratedNames,
    clearGeneratedNames,
    toggleBookmark,
    deleteGeneratedName,
    updateGeneratedName,
  };
});


export default useProgressStore;
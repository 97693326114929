async function getJson(p_url) {
    
    const response = await fetch(p_url);
    if (!response.ok) {
        throw new Error(`'${response.url}' not found`);
    }
    return await response.json();
    // Error shoud be catched in the function called
}

export default getJson;
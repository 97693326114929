import { ref } from 'vue';
import { defineStore } from 'pinia';

const useStoreInitialize = defineStore('initialize', () => {
  
  const initializers = [];

  const isInitialized = ref(false); // when ture initialzisen is done

  
  const addInitializer = (p_initializer) => {
    // Function to add an initializer promise to the array
    initializers.push(new Promise((resolve) => p_initializer(resolve)));
  };

  
  const initialize = () => {
    return Promise.all(initializers).then(() => {
      isInitialized.value = true;
      // if alle initializer are resolved, initialzierd is ready
    });
  };

  
  return {
    addInitializer,
    initialize,
    isInitialized,
  };
});

export default useStoreInitialize;
const handleAuthRest = async (path, method, body, session) => {
  let options = {
    method,
    headers: {
      'Authorization': `Bearer ${session.token}`,
    },
    credentials: 'include',
  };

  // Setze den Content-Type und Body nur, wenn er nicht null ist und die Methode nicht GET ist
  if (body != null && method !== 'GET') {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(body);
  }

  

  const makeRequest = async () => {
    const response = await fetch(`${process.env.VUE_APP_BASE_URL}${path}`, options);
    

    if (response.status === 401) {
      
      const refreshResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/account/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      

      console.log(refreshResponse.status, "REFRESH RESPONSE")
      if (refreshResponse.status === 200) {
        const { accessToken } = await refreshResponse.json();
        session.token = accessToken;
        options.headers['Authorization'] = `Bearer ${accessToken}`;
        console.log(accessToken, "ACCESS TOKEN")

        const makeRe = await makeRequest()
        console.log(await makeRe,  "ANOTEHR REQUEST")
        //return await makeRequest();
      } else {
        resetSession(session);
        throw new Error('Session expired. Please log in again!.');
      }
    }

    if (!response.ok) {
      resetSession(session);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.token) session.token = data.token;
    if (data.refreshToken) session.refreshToken = data.refreshToken;
    if (data.id) session.id = data.id;

    return method === 'GET' ? data : response;
  };

  return await makeRequest();
};

const resetSession = (session) => {
  console.log("RESET CALLED", session)
  
};

export default handleAuthRest;
<script setup>
import LayoutMain from '@/layouts/LayoutMain.vue'
import useStoreInitialize from './store/StoreInitialize';
import useStoreUser from './store/StoreUser';


const initializer = useStoreInitialize();
// dont need it for now 
useStoreUser();

// I dont have store which "neeeds" to be initialized except userstore
initializer.initialize() // checks if all promises are resolved in the initalizer


</script>

<template>
  <div>
    <head>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    </head>
    <body class="p-0 m-0 bg-white">
      <LayoutMain/>
    </body>
  </div>
</template>

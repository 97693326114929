<script setup>
import { ref } from 'vue';

const linkItems = ref([
    { path: '/', name: 'Home' },
    { path: '/login', name: 'Login' },
    { path: '/pricing', name: 'Pricing' },
    { path: '/dashboard', name: 'Dashboard' },
    { path: '/about', name: 'About' },
    { path: '/legal', name: 'Legal' },
    { path: '/support', name: 'Support' }
]);
</script>

<template>
    <footer class="">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
            <path fill="#58CB05" fill-opacity="1" d="M0,128L288,96L576,160L864,128L1152,96L1440,32L1440,320L1152,320L864,320L576,320L288,320L0,320Z"></path>
        </svg>
        <div class="bg-primary w-full py-10 text-lg lg:text-2xl text-white px-5 lg:px-0">
            <div class="lg:w-1/2 m-auto">
                <ul class="flex items-center justify-between py-4 underline">
                    <li v-for="link in linkItems.slice(0, 4)" :key="link.path">
                        <router-link :to="link.path">{{ link.name }}</router-link>
                    </li>
                </ul>
                <div class="m-auto block">
                    <ul class="flex items-center w-full justify-around underline">
                        <li v-for="link in linkItems.slice(4)" :key="link.path">
                            <router-link :to="link.path">{{ link.name }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css'; 
import '@fortawesome/fontawesome-free/js/all';
import { createPinia } from 'pinia';
import getJson from './utils/getJson';
import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config'; // for the toast 
import Lara from '@primevue/themes/nora';

const pinia = createPinia();
const app = createApp(App);


const init = async () => {
  try {
    // load from public folder
    const config = await getJson('/config.json');
    
    
    app.provide('config', config);
    app.use(pinia); 
    app.use(router);
    app.use(PrimeVue, {
      theme: {
        preset: Lara
    }
    });
    app.use(ToastService);
    // Mounten der App
    
    app.mount('#app');
  } catch (error) {
    console.error('Failed to load configuration:', error);
  }
};

// initaliszed on app load 
window.addEventListener('load', init);
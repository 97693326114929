<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import useStoreUser from '@/store/StoreUser';
import ButtonCustom from '@/components/input/ButtonCustom.vue';
import { IconViewDashboard, IconTagMultiple, IconLoginVariant, IconMagic, IconLogout } from '@iconify-prerendered/vue-mdi';

// Reactive state
const active = ref(true);
const userStore = useStoreUser();

// Navigation links
const navLinks = ref([
    { to: { name: 'generate' }, name: 'Generate', icon: IconMagic },
    { to: { name: 'dashboard' }, name: 'Dashboard', icon: IconViewDashboard },
    { to: { name: 'pricing' }, name: 'Pricing', icon: IconTagMultiple },
    { to: { name: 'login' }, name: 'Login', icon: IconLoginVariant }
]);

const authLinks = ref([
    { to: { name: 'generate' }, name: 'Generate', icon: IconMagic },
    { to: { name: 'dashboard' }, name: 'Dashboard', icon: IconViewDashboard },
    { to: { name: 'pricing' }, name: 'Pricing', icon: IconTagMultiple },
    { action: 'logout', name: 'Logout', icon: IconLogout }
]);

const displayedNavLinks = computed(() => {
    return userStore.isAuthorized ? authLinks.value : navLinks.value;
});

const toggleMobile = () => {
    active.value = !active.value;
};

const router = useRouter();

const logoutUser = () => {
    userStore.logout(); // Assuming you have a logout method in your user store
    router.push({ name: 'login' });
};

</script>

<template>
    <header>
        <nav class="bg-primary flex flex-col lg:flex-row items-center py-4 px-5 lg:px-0">
            <div class="flex-1 w-full py-2 lg:w-max flex flex-start lg:justify-center items-center">
                <router-link :to="{ name: 'home' }">
                    <div class="bg-white w-max rounded-lg px-3">
                        <span class="text-bold font-kanit text-3xl text-secondary">Validate</span>
                        <span class="text-bold text-3xl text-blackprimary">Name</span>
                    </div>
                </router-link>
                <div class="flex-1 flex lg:hidden justify-end items-center">
                    <ButtonCustom @click="toggleMobile" class="text-3xl lg:text-4xl text-white hover:text-grayprimary">
                        <i class="fas fa-bars"></i>
                    </ButtonCustom>
                </div>
            </div>
            <div class="flex-1">
                <ul v-show="active" class="flex flex-col lg:flex-row justify-between">
                    <li 
                        v-for="link in displayedNavLinks" 
                        :key="link.name" 
                        class="hover:text-grayprimary text-white mx-3 flex justify-center items-center"
                    >
                        <router-link 
                            v-if="link.to"
                            active-class="text-2xl lg:text-3xl underline text-secondary" 
                            :to="link.to" 
                            class="flex text-2xl lg:text-3xl ml-3 items-center justify-center hover:text-secondary hover:underline"
                        >
                            <component :is="link.icon" class="scale-120 text-white"></component>
                            <span class="ml-2 text-white">{{ link.name }}</span>
                        </router-link>
                        <a 
                            v-else
                            @click="logoutUser"
                            class="flex text-2xl lg:text-3xl hover:underline ml-3 items-center justify-center cursor-pointer"
                        >
                            <component :is="link.icon" class="scale-120 text-white"></component>
                            <span class="ml-2 text-white">{{ link.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-1"></div>
        </nav>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 90 1440 160">
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="1" dy="-14" stdDeviation="10"/>
                </filter>
            </defs>
            <path fill="#58CB05" fill-opacity="1" filter="url(#shadow)" d="M0,192L288,160L576,128L864,96L1152,192L1440,64L1440,0L1152,0L864,0L576,0L288,0L0,0Z"></path>
        </svg>
    </header>
</template>